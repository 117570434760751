import HeaderBg from "components/layout/HeaderBg"
import Layout from "components/layout/Layout"
import SEO from "components/layout/SEO"
import Link from "components/ui/Link"
import MaxWidthWrapper from "components/ui/MaxWidthWrapper"
import { PageProps } from "gatsby"
import * as React from "react"

const PageNotFound = (props: PageProps) => {
  return (
    <Layout location={props.location}>
      <SEO title="MN: Page not found"/>
      <MaxWidthWrapper>
        It seems you got lost. Click{" "}
        <Link href="/" isGatsby={true}>
          {" "}
          here
        </Link>{" "}
        to go back to the home page.
      </MaxWidthWrapper>
    </Layout>
  )
}

export default PageNotFound
